class PagesJekyllConfig extends HTMLElement {
  initialSourceBranch: string | null
  initialSourceSubdir: string | null
  sourceBranch: string | null
  sourceSubdir: string | null

  changeListener(event: Event) {
    const target = event.target as HTMLInputElement
    if (target.name === 'source') {
      this.sourceBranchListener(target)
    } else if (target.name === 'source_dir') {
      this.sourceSubdirListener(target)
    }
  }

  sourceBranchListener(target: HTMLInputElement) {
    this.sourceBranch = target.value

    const sourceDirSelector = this.querySelector<HTMLElement>('.js-jekyll-source-dir')!
    const branchIcon = this.querySelector<HTMLElement>('.js-branch-icon')!

    if (target.value !== '') {
      branchIcon.hidden = false
      sourceDirSelector.hidden = false
    } else {
      branchIcon.hidden = true
      sourceDirSelector.hidden = true
    }

    this.enableSubmitButton()
  }

  sourceSubdirListener(target: HTMLInputElement) {
    this.sourceSubdir = target.value
    this.enableSubmitButton()
  }

  enableSubmitButton() {
    const submitButton = this.querySelector<HTMLButtonElement>('.js-jekyll-submit')!
    if (submitButton.getAttribute('data-always-enabled') === 'true') return
    submitButton.disabled =
      this.initialSourceBranch === this.sourceBranch && this.initialSourceSubdir === this.sourceSubdir
  }

  connectedCallback() {
    this.initialSourceBranch = this.sourceBranch = this.getAttribute('data-initial-source')
    this.initialSourceSubdir = this.sourceSubdir = this.getAttribute('data-initial-subdir')
    this.addEventListener('change', this.changeListener)
  }

  disconnectedCallback() {
    this.removeEventListener('change', this.changeListener)
  }
}

if (!window.customElements.get('pages-jekyll-config')) {
  window.customElements.define('pages-jekyll-config', PagesJekyllConfig)
}
