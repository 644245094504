// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function copyInput(inputToCopy: HTMLInputElement): HTMLInputElement {
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', inputToCopy.name)
  input.setAttribute('value', inputToCopy.value)
  return input
}

// Add the checked alerts to all the bulk action forms
function copyCheckedAlertsToBulkActionForms(container: Element) {
  let disableReopenButton = false
  const fixedAlertNumbersElements = container.querySelectorAll<HTMLElement>('.js-scanning-fixed-alert-numbers')
  const fixedAlertNumbers: number[] =
    fixedAlertNumbersElements.length !== 0
      ? JSON.parse(fixedAlertNumbersElements[0].getAttribute('data-numbers') || '[]')
      : []

  for (const items of container.querySelectorAll<HTMLElement>('.js-scanning-bulk-action-items')) {
    // Remove any previous alert elements from the form
    items.textContent = ''

    // Copy the checked items to the form
    const checkedAlerts = container.querySelectorAll<HTMLInputElement>(
      '.js-scanning-alert-list [data-check-all-item]:checked'
    )
    for (const checked of checkedAlerts) {
      // If any of the checked alerts are fixed, then disable the reopen button
      // as it only works for manually resolved alerts
      disableReopenButton ||= fixedAlertNumbers.indexOf(parseInt(checked.value)) !== -1
      items.appendChild(copyInput(checked))
    }
  }

  for (const reopenButton of container.querySelectorAll<HTMLButtonElement>('.js-scanning-reopen-button')) {
    reopenButton.hidden = disableReopenButton
  }
  for (const reopenButtonDisabled of container.querySelectorAll<HTMLButtonElement>(
    '.js-scanning-reopen-button-disabled'
  )) {
    reopenButtonDisabled.hidden = !disableReopenButton
  }
}

on('click', '.js-file-toggle-show', ({currentTarget}) => {
  const hideFileToggleElement = currentTarget.nextElementSibling as HTMLElement
  if (!hideFileToggleElement || !hideFileToggleElement.classList.contains('js-file-toggle-hide')) {
    return
  }

  hideFileToggleElement.focus()
})

on('click', '.js-file-toggle-hide', ({currentTarget}) => {
  const showFileToggleElement = currentTarget.previousElementSibling as HTMLElement

  if (!showFileToggleElement || !showFileToggleElement.classList.contains('js-file-toggle-show')) {
    return
  }

  showFileToggleElement.focus()
})

on('change', '.js-scanning-alert-check', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-scanning-alert-list')!
  const checked = container.querySelector('.js-scanning-alert-check:checked') != null
  const filterOptions = container.querySelector<HTMLElement>('.js-scanning-filter-options')
  const bulkActions = container.querySelector<HTMLElement>('.js-scanning-alert-bulk-actions')
  const alertLinks = container.querySelector<HTMLElement>('.js-scanning-alert-links')

  if (filterOptions) {
    filterOptions.hidden = checked
  }
  if (bulkActions) {
    bulkActions.hidden = !checked
  }
  if (alertLinks) {
    alertLinks.hidden = checked
  }
  copyCheckedAlertsToBulkActionForms(container)
})

on(
  'details-menu-selected',
  '.js-scanning-code-path-menu',
  function (event) {
    const targetCodePath = event.detail.relatedTarget.getAttribute('data-target-code-path')
    for (const codePath of document.querySelectorAll<HTMLElement>('.js-scanning-code-path')) {
      codePath.hidden = targetCodePath !== codePath.getAttribute('data-code-path')
    }
  },
  {capture: true}
)
