import {getState, replaceState} from '../history'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on(
  'click',
  '.js-actions-runner-platform-radio',
  function (event) {
    const target = event.target as Element
    const selection = target.closest<HTMLElement>('.js-actions-runner-instructions')!
    const architectureMenu = selection.querySelector<HTMLElement>('.js-actions-runner-architecture-menu')!

    const os = target.getAttribute('data-download-os')!
    const defaultArchitecture = architectureMenu.getAttribute('data-default-architecture')!

    for (const el of architectureMenu.querySelectorAll<HTMLElement>('[data-download-os]')) {
      const elementOS = el.getAttribute('data-download-os')!
      el.hidden = elementOS !== os
      if (elementOS === os && el.getAttribute('data-download-architecture') === defaultArchitecture) {
        el.click()
      }
    }
    selection.querySelector<HTMLElement>('.js-actions-runner-windows-instructions')!.hidden = os !== 'win'
    selection.querySelector<HTMLElement>('.js-actions-runner-windows-arm64-preview')!.hidden = !(
      os === 'win' && defaultArchitecture === 'arm64'
    )
    selection.querySelector<HTMLElement>('.js-actions-runner-unix-instructions')!.hidden = os === 'win'
    updateUrlParams('os', os)
  },
  {capture: true}
)

on(
  'details-menu-selected',
  '.js-actions-runner-architecture-menu',
  function (event) {
    const target = event.detail.relatedTarget as Element
    const selection = target.closest<HTMLElement>('.js-actions-runner-instructions')!
    const downloadUrl = target.getAttribute('data-download-url')!
    const filename = target.getAttribute('data-download-filename')!
    const token = target.getAttribute('data-download-token')!
    const hash = target.getAttribute('data-download-hash')!
    const os = target.getAttribute('data-download-os')!

    for (const el of selection.querySelectorAll('.js-action-runner-url')) {
      el.textContent = downloadUrl
    }

    for (const el of selection.querySelectorAll('.js-action-runner-filename')) {
      el.textContent = filename
    }

    for (const el of selection.querySelectorAll('.js-action-runner-token')) {
      el.textContent = token
    }

    for (const el of selection.querySelectorAll('.js-action-runner-hash')) {
      el.textContent = hash
    }
    const architecture = target.getAttribute('data-download-architecture')!
    selection.querySelector<HTMLElement>('.js-actions-runner-windows-arm64-preview')!.hidden = !(
      os === 'win' && architecture === 'arm64'
    )
    updateUrlParams('arch', architecture)
  },
  {capture: true}
)

function updateUrlParams(key: string, value: string) {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set(key, value)

  const url = new URL(window.location.href, window.location.origin)
  url.search = urlParams.toString()
  replaceState(getState(), '', url.toString())
}
