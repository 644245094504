// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {fetchSafeDocumentFragment} from '../fetch'
import {requestSubmit} from '../form'

// When the user creates a new label, update the list of available labels
//to choose from, so the user can immediately apply their new label.
on(
  'submit',
  '.js-create-runner-label-form',
  async function (event: SubmitEvent) {
    event.preventDefault()

    let response
    const form = event.currentTarget as HTMLFormElement
    const labelsMenu = form.closest<HTMLElement>('.js-runner-labels-menu')!
    const errorContainer = labelsMenu.querySelector<HTMLElement>('.js-runner-label-error')!

    try {
      response = await fetchSafeDocumentFragment(document, form.action, {
        method: form.method,
        body: new FormData(form)
      })
    } catch (error) {
      if (error.response.json?.message && errorContainer) {
        errorContainer.textContent = error.response.json.message
        errorContainer.hidden = false
      }

      return
    }

    if (!response) return

    if (errorContainer) {
      errorContainer.hidden = true
      errorContainer.textContent = ''
    }

    const input = response.querySelector<HTMLInputElement>('.js-label-input')
    const list = labelsMenu.querySelector<HTMLElement>('.js-filterable-runner-labels')!
    list.prepend(response)

    // Select added label. This will trigger any listeners for label selection as well
    input?.click()

    const field = labelsMenu.querySelector<HTMLInputElement>('.js-label-filter-field')!
    field.value = ''
    field.focus()
  },
  {capture: true}
)

function submitOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const form = currentTarget.querySelector<HTMLFormElement>('.js-runner-labels-form')!
    requestSubmit(form)
  }
}

function addLabelsOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const labels = currentTarget.querySelector<HTMLDivElement>('.js-filterable-runner-labels')!
    const menuItems = labels.getElementsByClassName('select-menu-item')
    const checkedLabels = []
    for (const menuItem of menuItems) {
      if (menuItem.attributes.getNamedItem('aria-checked')?.value === 'true') {
        const labelElement = menuItem.querySelector('.select-menu-item-text')
        if (labelElement instanceof HTMLElement) {
          checkedLabels.push(labelElement.textContent)
        }
      }
    }

    const containerDiv = currentTarget.closest<HTMLDivElement>('.js-label-container')!
    const labelTemplate = containerDiv.querySelector<HTMLDivElement>('#custom-hosted-runner-label-template')!
    const detailsMenu = containerDiv.querySelector('#custom-hosted-runner-label-details-list')!

    for (let i = containerDiv.children.length - 2; i > 0; i--) {
      if (containerDiv.children[i].classList.contains('js-user-label')) {
        containerDiv.removeChild(containerDiv.children[i])
      } else {
        // Remove checked labels that match system labels' names
        let index = -1
        // Labels are case-insensitive
        const isLabelMatch = (label: string | null) =>
          containerDiv.children[i].textContent?.trim().toLowerCase() === label?.toLowerCase()
        do {
          index = checkedLabels.findIndex(isLabelMatch)
          if (index !== -1) {
            checkedLabels.splice(index, 1)
          }
        } while (index !== -1)
      }
    }

    for (const label of checkedLabels) {
      const node = labelTemplate.cloneNode(true)
      if (node instanceof HTMLElement) {
        node.removeAttribute('hidden')
        node.textContent = label
        containerDiv.appendChild(node)
      }
    }

    containerDiv.appendChild(detailsMenu)
  }
}

// On item selection, add listener to submit form on menu close
on(
  'details-menu-selected',
  '.js-runner-labels-details',
  function (event) {
    const menu = event.currentTarget
    menu.addEventListener('toggle', submitOnMenuClose, {once: true})
  },
  {capture: true}
)

// On item selection, add listener to submit form on menu close
on(
  'details-menu-selected',
  '.js-runner-labels-details-list',
  function (event) {
    const menu = event.currentTarget
    menu.addEventListener('toggle', addLabelsOnMenuClose, {once: true})
  },
  {capture: true}
)
